import React from "react";
import styles from "./Included.module.css";
import logo from "../../images/logo.png";
import check2 from "../../images/check.png";

const Included = (props) => {
  const { alt, bullets, premium, title } = props;

  return (
    <div className={styles.main}>
      {/* {premium && <img className={styles.icon} src={logo} alt={alt} />} */}
      <h3>{title}</h3>
      <ul>
        {bullets?.map((bullet, index) => {
          let isSpecial = bullet.charAt(0) === "!";
          let isIndented = bullet.includes(">");

          if (isSpecial) bullet = bullet.slice(1);
          if (isIndented) bullet = bullet.slice(0, -1);

          return (
            <li
              className={styles.bullet}
              key={index}
              style={{ marginLeft: isIndented ? "2rem" : null }}
            >
              <img
                src={isSpecial ? logo : check2}
                alt="check"
                className={styles.check}
              />
              <span>{bullet}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Included;
