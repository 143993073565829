import React from "react";
import css from "./Benefit.module.css";

const Benefit = ({ alt, description, src, title }) => {
  return (
    <div className={`${css.main} benefit`}>
      <img className={css.icon} src={src} alt={alt} />
      <h3 className={css.title}>{title}</h3>
    </div>
  );
};

export default Benefit;
