import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Benefit from "../components/Benefit-1/Benefit";

import compliance from "../images/audit.png";
import funding from "../images/funding.png";
import guidance from "../images/guidance.png";
import minimize from "../images/minimize.png";
import time from "../images/time.png";

const Benefits = () => {
  useEffect(() => {
    const slideFromLeft = (entries) => {
      entries?.forEach((entry) => {
        entry.target.classList.toggle("benefit_show", entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(slideFromLeft, {
      rootMargin: "-180px",
    });

    const benefits = document.getElementsByClassName("benefit");

    Array.from(benefits).forEach((benefit) => {
      observer.observe(benefit);
    });
  }, []);

  return (
    <div className="section background">
      <h2>How you benefit:</h2>
      <div className="benefits">
        <Benefit
          src={funding}
          alt="bar chart with increasingly tall bars moving from left to right, as well as an arrow pointing upward and to the right."
          title="Make and Save More Money"
        />
        <Benefit
          src={minimize}
          alt="bar chart with increasingly tall bars moving from left to right, as well as an arrow pointing upward and to the right."
          title="Reduce Stress and Anxiety"
        />
        <Benefit
          src={compliance}
          alt="bar chart with increasingly tall bars moving from left to right, as well as an arrow pointing upward and to the right."
          title="Increase Accuracy and Compliance"
        />
        <Benefit
          src={time}
          alt="bar chart with increasingly tall bars moving from left to right, as well as an arrow pointing upward and to the right."
          title="More Time and Efficiency"
        />
        <Benefit
          src={guidance}
          alt="bar chart with increasingly tall bars moving from left to right, as well as an arrow pointing upward and to the right."
          title="Make Insight-Driven Decisions"
        />
      </div>
      <a
        className="link"
        href="https://calendar.app.google/cgho9MaUp1oADCZLA"
        target="_blank"
        rel="noopener noreferrer"
      >
        Get Started
      </a>
    </div>
  );
};

export default Benefits;
