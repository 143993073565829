import React, { useEffect, useState } from "react";

import anchor from "../images/anchor.png";
import anvil from "../images/anvil.png";
import boulder from "../images/boulder.png";
import kettlebell from "../images/kettlebell.png";

const Intro = ({ image, primary, secondary }) => {
  const [index, setIndex] = useState(0);

  const images = [
    { src: anchor, alt: "an anchor with the word 'Payroll' written on it" },
    {
      src: anvil,
      alt: "an anvil with the words 'Financial Consulting' written on it",
    },
    {
      src: kettlebell,
      alt: "a kettlebell with the word 'Bookkeeping' written on it",
    },
    {
      src: boulder,
      alt: "a boulder with the words 'New Business Registration' written on it",
    },
  ];

  useEffect(() => {
    if (!image) {
      const timer = setInterval(() => {
        setIndex((old) => {
          let value = old === images.length - 1 ? 0 : old + 1;
          return value;
        });
      }, 3000);

      return () => clearInterval(timer);
    }
  }, [image, images.length]);

  return (
    <div className="section intro" style={{ overflow: "hidden" }}>
      <h1>{primary}</h1>
      <p>{secondary}</p>
      <a
        className="link"
        href="https://calendar.app.google/cgho9MaUp1oADCZLA"
        target="_blank"
        rel="noopener noreferrer"
      >
        Schedule a Free Consultation
      </a>
      {image ? (
        <img
          src={images[image].src}
          alt={images[image].alt}
          className={`intro_image intro_image_shown`}
        />
      ) : (
        images.map((image, i) => (
          <img
            src={image.src}
            alt={image.alt}
            key={i}
            className={`intro_image intro_image_${
              i === index ? "shown" : "hidden"
            }`}
          />
        ))
      )}
    </div>
  );
};

export default Intro;
