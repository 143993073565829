import React from "react";
import Header from "../components/Header/Header";
import Intro from "../sections/Intro";
import Included from "../components/Included/Included";
import Benefit from "../components/Benefit-2/Benefit";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import L2R1 from "../components/L2R1/L2R1";

import audit from "../images/audit.png";
import guidance from "../images/guidance.png";
import justMe from "../images/justMe.png";
import maximize from "../images/maximize.png";
import perfect from "../images/perfect.png";
import time from "../images/time.png";

import small from "../images/small.png";
import medium from "../images/medium.png";
import large from "../images/large.png";

const Payroll = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="We'll oversee your payroll."
        secondary="We'll make payday a great day for everybody by handling everything for you."
        image="0"
      />
      <div className="section background">
        <h2>See what's included.</h2>
        <Included
          title="Payroll Processing"
          bullets={[
            "Bi-Weekly and Semi-Monthly Options",
            "Preparation and Submission",
            "Summary and Detail Reports",
          ]}
        />
        <Included
          title="Payroll Maintenance"
          bullets={[
            "Time Tracking for Hourly Employees",
            "New Hire Setup",
            "Terminated Employee Inactivation",
            "Custom Reports Available",
          ]}
        />
        <Included
          title="State and Federal Requirements"
          bullets={[
            "W-2 Preparation",
            "Withholding Account Management",
            "Unemployment Account Management",
            "New Hire Reporting",
          ]}
        />
        <a
          className="link"
          href="https://calendar.app.google/cgho9MaUp1oADCZLA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Free Consultation
        </a>
      </div>
      <div className="section">
        <h2>Check out the many benefits.</h2>
        <div className="benefits-2">
          <Benefit
            title="Save Time"
            src={time}
            alt="clock with arrow pointing backwards"
          />
          <Benefit title="Minimize Mistakes" src={perfect} alt="100%" />
          <Benefit
            title="Financial Guidance"
            src={guidance}
            alt="branch of 3 arrows stemming from a source"
          />
          <Benefit
            title="Stress-Free Audits"
            src={audit}
            alt="magnifying glass spying a checkmark"
          />
          <Benefit
            title="Improved Cash Flow"
            src={maximize}
            alt="bar chart with arrow showing upward trend"
          />
        </div>
        <a
          className="link"
          href="https://calendar.app.google/cgho9MaUp1oADCZLA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Free Consultation
        </a>
      </div>
      <div className="section background">
        <h2>Just look at those prices.*</h2>
        <span>*Prices represent a monthly recurring charge.</span>
        <div className="pricing">
          <L2R1
            left="Just Me"
            right="$125"
            src={justMe}
            alt="a stick figure person"
          />
          <L2R1
            left="2 - 24 Employees"
            right="$250"
            src={small}
            alt="small building"
          />
          <L2R1
            left="25 - 49 Employees"
            right="$500"
            src={medium}
            alt="medium building"
          />
          <L2R1
            left="50+ Employees"
            right="$750"
            src={large}
            alt="large building"
          />
        </div>
        <a
          className="link"
          href="https://calendar.app.google/cgho9MaUp1oADCZLA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Free Consultation
        </a>
      </div>
      <Footer background />
    </div>
  );
};

export default Payroll;
